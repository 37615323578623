<template>
  <div class="row p-strip">
    <div class="col-12 u-align-text--center">
      <h1>404 - Pagina niet gevonden</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNotFound"
}
</script>

<style scoped lang="scss">

</style>