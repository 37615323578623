<template>
  <div id="app" class="l-site">
    <Navigation></Navigation>
    <main>
      <transition name="fade" appear mode="out-in">
        <router-view/>
      </transition>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import Footer from "@/components/Footer.vue";

export default {
  name: 'App',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Creative webdeveloper & tech consultant',
    // all titles will be injected into this template
    titleTemplate: '%s | Sebastix - Sebastian Hagens',
    meta: [
      {
        name: 'description', content: 'Contactgegevens Sebastian Hagens'
      },
      {
        name: 'og:description',
        content: 'Als creatieve techneut help ik individuen en bedrijven met het oplossen van ambitieuze digitale vraagstukken'
      },
      {
        name: 'theme-color',
        content: '#131C4E'
      }
    ]
  },
  components: {
    Navigation,
    Footer
  },
  data() {
    return {

    }
  },
  methods: {

  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || 'Sebastix - Sebastian Hagens'
      }
    }
  },
}
</script>

<style lang="scss">

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--textColor);
  .p-strip:first-child {
    @media (min-width: 772px) {
      padding-top: 6rem;
    }
  }
}

.fade {
  &-enter-active,
  &-leave-active{
    transition: opacity 0.15s;
  }
  &-enter,
  &-leave-to {
    opacity: 0;
  }
}

</style>
