import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import Home from '../views/Home.vue'
import PageNotFound from "../views/PageNotFound";

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Creative webdeveloper & tech consultant | Sebastix - Sebastian Hagens',
      metaTags: [
        {
          name: 'description',
          content: 'Als creatieve techneut help ik individuen en bedrijven met het oplossen van ambitieuze digitale vraagstukken'
        },
        {
          name: 'og:title',
          content: 'Creative webdeveloper & tech consultant | Sebastix - Sebastian Hagens',
        },
        {
          name: 'og:description',
          content: 'Als creatieve techneut help ik individuen en bedrijven met het oplossen van ambitieuze digitale vraagstukken'
        },
        {
          name: 'og:image',
          content: document.location + require('/src/assets/sebastian-hagens-4.jpg'),
        }
      ]
    }
  },
  {
    path: '/:catchAll(.*)',
    component: PageNotFound,
    name: 'NotFound'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
