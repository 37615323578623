<template>
  <div class="home">
    <div class="p-strip">
      <div class="row">
        <div class="col-6 logo">
          <span class="u-align-text--center u-vertically-center">
            Sebastian Hagens
            <br />
            Creative webdeveloper & tech consultant
          </span>
          <img alt="Sebastix logo" src="@/assets/sebastix-logo.jpg" loading="lazy" class="u-align-text--center" />
        </div>
        <div class="col-6 u-align--center">
          <a href="#" v-on:click="toggleSebastian" class="photo">
            <img alt="Sebastian Hagens" :src="sebastianHagensSrc" :srcset="sebastianHagensSrcset" class="sebastian-hagens" v-bind:class="{ 'clicked' : isClicked }" v-on:mouseout="isClicked = false" />
          </a>
        </div>
      </div>
    </div>
    <div class="p-strip">
      <div class="row">
        <h1>
          sebastix.dev
        </h1>
      </div>
      <div class="row">
        <div class="col-12 u-align-text--center">
          <Button text="Bezoek sebastix.nl" class="benieuwd-c2a" href="https://sebastix.nl"></Button>
          <Button text="Lees mijn blog" class="benieuwd-c2a" href="https://sebastix.nl/blog"></Button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 u-align-text--center">
          <p>Niet gevonden wat je zocht? Kijk dan eens op <a href="https://sebastix.com" target="_blank">sebastix.com</a></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Button from '@/components/Button.vue'

export default {
  name: 'Home',
  metaInfo: {
    title: 'Creative webdeveloper & tech consultant',
    titleTemplate: '%s | Sebastix - Sebastian Hagens'
  },
  components: {
    Button
  },
  data() {
    return {
      // Random number between 1 and 4.
      toggleSebastianNumber: this.toggleSebastianNumber = Math.round(Math.random() * (4 - 1) + 1),
      sebastianHagensSrc: '',
      sebastianHagensSrcset: '',
      isClicked: false
    }
  },
  mounted() {
    this.toggleSebastian();
  },
  methods: {
    toggleSebastian: function(e) {
      // Add clicked class
      if (e !== undefined) {
        e.preventDefault();
        this.isClicked = true;
      }
      // Change src of image
      if (this.toggleSebastianNumber === 4) {
        this.toggleSebastianNumber = 1;
      } else {
        this.toggleSebastianNumber++;
      }
      this.sebastianHagensSrc = require('@/assets/sebastian-hagens-'+this.toggleSebastianNumber+'.jpg');
      this.sebastianHagensSrcset = require('@/assets/sebastian-hagens-'+this.toggleSebastianNumber+'@2x.jpg') + ' 2x';
    }
  }
}
</script>

<style scoped lang="scss">
  .home {
    .p-strip:first-child {
      padding-bottom: 0;
    }
    .logo {
      position: relative;
      font-family: "Ubuntu Mono",serif;
      text-align: center;
      display: flex;
      flex-flow: column;
      img {
        max-width: 50vw;
        order: 1;
        margin-bottom: 30px;
        @media (min-width: 772px) {
          position: absolute;
          top: calc(50% - 100px);
          left: 30px;
          height: 200px;
          margin: 0;
          z-index: 5;
        }
      }
      > span {
        order: 2;
        padding: 10px 0;
        border-radius: 100px;
        background-color: #000;
        width: 100%;
        margin-bottom: 30px;
        @media (min-width: 772px) {
          position: absolute;
          top: calc(85% - 40px);
          left: 200px;
          width: 400px;
          height: 80px;
          margin: 0;
          z-index: 3;
        }
        @media (min-width: 930px) {
          top: calc(50% - 40px);
        }
        @media (min-width: 1681px) {
          width: 440px;
        }
      }
    }
    .benieuwd-c2a {
      margin-top: 4rem;
    }
    a.photo {
      display: inline-block;
      border-bottom: 0;
      &:active {
        img.sebastian-hagens {
          clip-path: circle(47% at center);
          transform: scale(.925);
          /* */
          @media screen and (max-width: 767px) {
            transform: scale(.9);
          }
        }
      }
    }
    img.sebastian-hagens {
      transition: 0.4s cubic-bezier(1, -1, 0, 2);
      clip-path: circle(49% at center);
      &:hover {
        clip-path: circle(50% at center);
        transform: scale(1.015);
      }
      &.clicked {
        transition: 0.1s ease-out;
        &:hover {
          clip-path: circle(50% at center);
          transform: scale(1);
        }
      }
    }

    h1 {
      font-weight: 300;
      margin: 30px 0 30px 0;
      @media (min-width: 772px) {
        text-align: center;
        font-size: 58px;
        font-weight: 100;
        line-height: 70px;
      }
    }

    .tools {
      .row:first-child {
        .logos {
          @media (max-width: 772px) {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
          }
        }
        img.bureau-sebastian-hagens {
          @media (min-width: 772px) {
            clip-path: polygon(0 0, 70% 0, 100% 100%, 0% 100%);
          }
          @media (min-width: 1270px) {
            clip-path: polygon(0 0, 73% 0, 100% 100%, 0% 100%);
          }
          @media (min-width: 1681px) {
            clip-path: polygon(0 0, 80% 0, 100% 100%, 0% 100%);
          }
        }

        h2 {
          margin-top: 0.9rem;
          @media (min-width: 772px) {
            margin-top: 0.8rem;
            margin-left: -7.4rem;
          }
          @media (min-width: 930px) {
            margin-top: 0.8rem;
            margin-left: -10rem;
          }
        }

        p {
          @media (min-width: 772px) {
            margin-left: -5rem;
          }
          @media (min-width: 930px) {
            margin-left: -8.5rem;
          }
        }

        > .row > div:nth-child(2) {
          padding-left: 1rem;
          padding-right: 1rem;
        }
      }

      .skills {
        @media (min-width: 772px) {
          margin-left: -2rem;
        }
        @media (min-width: 930px) {
          margin-left: -4rem;
        }
        @media (min-width: 1270px) {
          margin-left: -5rem;
        }
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 10px;
        @media (min-width: 772px) {
          row-gap: 0;
        }

        .f-row {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: flex-start;
          flex: 0 1 100%;

          &:nth-child(1) {
            @media (min-width: 772px) {
              margin-left: calc(-29vw / 5 / 2);
            }
            @media (min-width: 930px) {
              margin-left: calc(-60px / 2);
            }
            @media (min-width: 1270px) {
              margin-left: calc(-80px / 2);
            }
          }

          &:nth-child(2) {
            @media (min-width: 772px) {
              margin-left: 5px;
            }
            @media (min-width: 930px) {
              margin-left: 5px;
            }
            @media (min-width: 1270px) {
              margin-left: 5px;
            }
          }

          &:nth-child(3) {
            @media (min-width: 772px) {
              margin-left: calc(29vw / 5 / 2 + 10px);
            }
            @media (min-width: 930px) {
              margin-left: calc(78px / 2);
            }
            @media (min-width: 1270px) {
              margin-left: calc(98px / 2);
            }
          }

          &:nth-child(4) {
            @media (min-width: 772px) {
              margin-left: calc(29vw / 5 + 14px);
            }
            @media (min-width: 930px) {
              margin-left: 76px;
            }
            @media (min-width: 1270px) {
              margin-left: 96px;
            }
          }

          .circle-bg {
            background-color: var(--textColor);
            text-align: center;
            width: calc(69vw / 5);
            height: calc(69vw / 5);
            margin: 0 5px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            // Between 772px and 930px the logos needs to be responsive
            @media (min-width: 772px) {
              width: calc(28vw / 5);
              height: calc(28vw / 5);
            }
            @media (min-width: 930px) {
              width: 60px;
              height: 60px;
            }
            @media (min-width: 1270px) {
              width: 80px;
              height: 80px;
            }
            @media (min-width: 1681px) {
              width: calc(35vw / 5);
              height: calc(35vw / 5);
            }

            img {
              width: 60%;
              @media (min-width: 1270px) {
                width: auto;
              }
            }
          }
        }
      }
    }
    .short-bio {
      padding-top: 1.5rem;
      p {

      }
    }
  }
</style>
